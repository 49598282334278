import styled from "styled-components";

export const SContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SButton = styled.button`
  display: flex;
  align-items: center;

  margin: 10px;
  border: 1px solid;
  border-radius: 5px;
  padding: 0.6rem 1rem;

  background-color: ${({ $active, theme }) => ($active ? theme.btnBgPrimaryHover : theme.btnBgPrimary)};
  border-color: ${({ theme }) => theme.btnBorderPrimary};
  color: ${({ theme }) => theme.btnTextPrimary};
  font-size: 1rem;
  font-family: Jost-Regular;
  text-decoration: none !important;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.btnBgPrimaryHover};
    border-color: ${({ theme }) => theme.btnBorderPrimaryHover};
  }
`;