import styled from "styled-components";
import { Link } from "react-router-dom";

const COMMON_RULE = `
  border: 1px solid;
  border-radius: 5px;
  padding: 0.6rem 1rem;

  font-size: 1rem;
  font-family: Jost-Regular;
  text-decoration: none !important;

  cursor: pointer;
`;

export const SBtnPrimary = styled.button`
  ${COMMON_RULE}

  background-color: ${({ theme }) => theme.btnBgPrimary};
  border-color: ${({ theme }) => theme.btnBorderPrimary};
  color: ${({ theme }) => theme.btnTextPrimary};

  &:hover {
    background-color: ${({ theme }) => theme.btnBgPrimaryHover};
    border-color: ${({ theme }) => theme.btnBorderPrimaryHover};
  }
`;


export const SLinkPrimary = styled(Link)`
  display:inline-block;
  ${COMMON_RULE}

  background-color: ${({ theme }) => theme.btnBgPrimary};
  border-color: ${({ theme }) => theme.btnBorderPrimary};
  color: ${({ theme }) => theme.btnTextPrimary};

  &:hover {
    background-color: ${({ theme }) => theme.btnBgPrimaryHover};
    border-color: ${({ theme }) => theme.btnBorderPrimaryHover};
  }
`;

export const SBtnGreen = styled.button`
  ${COMMON_RULE}

  background-color: ${({ theme }) => theme.btnBgGreen};
  border-color: ${({ theme }) => theme.btnBorderGreen};
  color: ${({ theme }) => theme.btnTextGreen};

  &:hover {
    background-color: ${({ theme }) => theme.btnBgGreenHover};
    border-color: ${({ theme }) => theme.btnBorderGreenHover};
  }
`;

export const SBtnRed = styled.button`
  ${COMMON_RULE}

  background-color: ${({ theme }) => theme.btnBgRed};
  border-color: ${({ theme }) => theme.btnBorderRed};
  color: ${({ theme }) => theme.btnTextRed};

  &:hover {
    background-color: ${({ theme }) => theme.btnBgRedHover};
    border-color: ${({ theme }) => theme.btnBorderRedHover};
  }
`;