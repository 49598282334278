import axios from "./axios";

export default async function apiGetMarketProducts(offset) {
  return await axios.get(`/market-products?offset=${offset}`,
    {
      headers: { 'Content-Type': 'application/json' }
    }
  );
}

