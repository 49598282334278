import { createContext, useState } from "react";

export const CartContext = createContext(null);

/* Structur array
  [
    {
      id: 1,
      quantity: 2
      price: 45.00
    },
    {
      id: 4,
      quantity: 1
      price: 10.00
    },
  ]
*/

export const CartContextProvider = (props) => {
  const [cartItems, setCartItems] = useState([]);
  const [shipment, setShipment] = useState();

  const addToCart = (newItem) => {
    const isInTheCart = (newItem) => {
      for (const i in cartItems) {
        if (cartItems[i].id === newItem.id) {
          return true;
        }
      }
      return false;
    }

    const addItemToCart = (newItem) => {
      setCartItems([...cartItems, {
        id: newItem.id,
        name: newItem.name,
        quantity: 1,
        price: newItem.price
      }]);

      return true;
    }

    return isInTheCart(newItem) ? false : addItemToCart(newItem);
  };

  const getCart = () => {
    return cartItems;
  }

  const removeFromCart = (itemId) => {
    setCartItems(cartItems.filter((item) => item.id !== itemId));
  }

  const updateCartItemCount = (itemId, newAmount) => {
    setCartItems(current =>
      current.map(item => {
        if (item.id === itemId) {
          return {...item, quantity: newAmount};
        }
        return item;
      }),
    );
  };

  const updateShipment = (newShipment) => {
    setShipment(newShipment)
  };

  const getShipment = () => {
    return (shipment !== undefined) ? shipment : false;
  };

  const getCartAmount = () => {
    let totalAmount = 0;
    for (const i in cartItems) {
      totalAmount += cartItems[i].quantity * cartItems[i].price
    }
    return totalAmount;
  }

  const getTotalCartAmount = () => {
    return (shipment !== undefined) ? ( getCartAmount() + Number(shipment.price) ) : getCartAmount();
  };

  const isEmptyCart = () => {
    return getCart()?.length !== 0 ? false : true;
  }

  const contextValue = {
    addToCart,
    getCart,
    removeFromCart,
    updateCartItemCount,
    updateShipment,
    getShipment,
    getCartAmount,
    getTotalCartAmount,
    isEmptyCart
  };

  return (
    <CartContext.Provider value={contextValue}>
      {props.children}
    </CartContext.Provider>
  );
};