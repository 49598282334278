import styled from "styled-components";

export const SSideContainer = styled.div`
  width: 100%;
  height: auto;
`;

export const SSide = styled.div`
  float: left;
  width: 50%;

  @media (max-width: 768px) {
    float: none;
    width: 100%;
  }
`;

export const SImgSideMd = styled.div`
  float: left;

  height: 500px;
  width: 50%;

  background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  background-position: center center;

  @media (max-width: 768px) {
    float: none;
    width: 100%;
  }
`;

export const SImgSideLg = styled.div`
  float: left;

  height: 100vh;
  width: 50%;

  background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  background-position: center center;

  @media (max-width: 768px) {
    float: none;
    width: 100%;
  }
`;