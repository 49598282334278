import styled from "styled-components";

export const SDivider10 = styled.div`
  width: 100%;
  height: 10px;
`;

export const SDivider20 = styled.div`
  width: 100%;
  height: 20px;
`;

export const SDivider30 = styled.div`
  width: 100%;
  height: 30px;
`;

export const SDivider40 = styled.div`
  width: 100%;
  height: 40px;
`;

export const SDivider50 = styled.div`
  width: 100%;
  height: 50px;
`;

export const SDivider60 = styled.div`
  width: 100%;
  height: 60px;
`;

export const SDivider70 = styled.div`
  width: 100%;
  height: 70px;
`;

export const SDivider80 = styled.div`
  width: 100%;
  height: 80px;
`;

export const SDivider90 = styled.div`
  width: 100%;
  height: 90px;
`;

export const SDivider100 = styled.div`
  width: 100%;
  height: 100px;
`;