import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import Head from '../../../layouts/head';
import Header from '../../../components/header';
import Footer from '../../../components/footer';
import useAxiosPrivate from '../../../features/hooks/useAxiosPrivate';
import { BannerError } from '../../../components/banner';
import { PaginationStep } from '../../../components/pagination';
import { SDivider10, SDivider30, SDivider50, SDivider100 } from '../../../styles/dividerStyles';
import { STable, STbodyTd, STheadTh } from '../../../styles/tableStyles';
import { formatDay } from '../../../utils/date';
import { formatCurrency } from '../../../utils/string';

export default function AccountOrder() {
  const { t } = useTranslation();

  return (
    <>
      <Head
        title={t('orders-title', { brand: `${process.env.REACT_APP_APP_NAME}` })}
        path='orders'
      />
      <Header/>
      <SDivider50/>
      <Main/>
      <SDivider100/>
      <Footer/>
    </>
  );
}

const Main = () => {
  const { t } = useTranslation();

  return(
    <main className='container'>
      <h2>{t('orders-h1')}</h2>
      <SDivider30/>

      <Orders/>
    </main>
  );
}

const Orders = () => {
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const [errMsg, setErrMsg] = useState();
  const [orders, setOrders] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const getOrders = async () => {
      try {
        setErrMsg("");

        const response = await axiosPrivate.get(`/orders?offset=${offset}`);
        if (response.data.lenght === 0) {
          handlePageChange(currentPage - 1);
        } else {
          setOrders(response.data);
        }
      } catch (err) {
        if (!err?.response) {
          setErrMsg("No Server Response");
        }
      }
    }

    getOrders();

    // eslint-disable-next-line
  }, [offset]);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
    setOffset((selectedPage * itemsPerPage) - itemsPerPage);
  }

  return (
    <>
      <BannerError>{errMsg}</BannerError>

      <STable>
        <thead style={{textAlign: "center"}}>
          <tr>
            <STheadTh>{t('orders-id')}</STheadTh>
            <STheadTh>{t('orders-details')}</STheadTh>
            <STheadTh>{t('orders-total')}</STheadTh>
            <STheadTh>{t('orders-data')}</STheadTh>
            <STheadTh></STheadTh>
          </tr>
        </thead>

        <tbody>
          { orders?.length ? orders.map((order, i) =>
              <Row order={order} key={i}/>
            ) :
            <tr className='text-center'>
              <td style={{padding: "12px"}} colSpan="5">
                <strong>{t('orders-no_order')}</strong>
                <SDivider10/>
                <Link to={t('url-market')}>{t('orders-start')}</Link>
              </td>
            </tr>
          }
        </tbody>
      </STable>

      <PaginationStep
        currentPage={currentPage}
        onChange={handlePageChange}
      />
    </>
  );
}

const Row = ({ order }) => {
  const { t } = useTranslation();

  return (
    <tr style={{textAlign: "center"}}>
      <STbodyTd>
        <strong>{order.id}</strong>
      </STbodyTd>
      <STbodyTd>
        { order.products.map((product, i) =>
          <div>
            <SDivider10/>
            <p key={i}>
              {product.quantity} x {product.name} - {product.price} $
            </p>
            <SDivider10/>
          </div>
        )}
      </STbodyTd>
      <STbodyTd>
        <strong>{formatCurrency(order.total.toPay)} $</strong>
      </STbodyTd>
      <STbodyTd>
        {formatDay(order.date)}
      </STbodyTd>
      <STbodyTd>
        {t('orders-download_invoice')}
      </STbodyTd>
    </tr>
  );
}