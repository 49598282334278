import React from 'react';
import { useTranslation } from "react-i18next";

import Head from '../../../layouts/head';
import Header from '../../../components/header';
import Footer from '../../../components/footer';
import { SDivider30, SDivider100 } from '../../../styles/dividerStyles';

export default function RecoveryAccount() {
  const { t } = useTranslation();

  return (
    <>
      <Head
        title={t('recovery_account-title', { brand: `${process.env.REACT_APP_APP_NAME}` })}
        description={t('recovery_account-description', { brand: `${process.env.REACT_APP_APP_NAME}` })}
        keyword={t('recovery_account-keyword')}
        path='recovery_account'
      />
      <Header/>
      <SDivider30/>
      <Main/>
      <SDivider100/>
      <Footer/>
    </>
  );
}

const Main = () => {
  return(
    <main className='container'>
      Recupera account
    </main>
  );
}