import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useAuth from "./hooks/useAuth";

const RequireAuth = ({ allowedRoles }) => {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const location = useLocation();

  return (
    auth?.roles?.find(role => allowedRoles?.includes(role))
      ? <Outlet />
      : auth?.user
        ? <Navigate to={t('url-login')} state={{ from: location }} replace />
        : <Navigate to={t('url-login')} state={{ from: location }} replace />
  );
}

export default RequireAuth;