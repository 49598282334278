import styled from "styled-components";

const COLOR = `
  color: #000;
  background: #fff;
`;

export const SContainer = styled.div`
  margin: 0px auto;
  border: 1px solid ${({ theme }) => theme.bg3};
  width: 150px;

  line-height: normal;
  text-align: center;
  ${COLOR}
`;

export const SDown = styled.button`
  float: left;
  display: inline;

  border-top: none;
  border-right: 1px solid ${({ theme }) => theme.bg3};
  border-bottom: none;
  border-left: none;
  padding: 13px 14px 9px;

  cursor: pointer;
  ${COLOR}
`;

export const SUp = styled.button`
  float: right;
  display: inline;

  border-top: none;
  border-right: none;
  border-bottom: none;
  border-left: 1px solid ${({ theme }) => theme.bg3};
  padding: 13px 14px 9px;

  cursor: pointer;
  ${COLOR}
`;

export const SValue = styled.div`
  display: inline;

  line-height: 38px;
`;