import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import Head from '../../../layouts/head';
import Header from '../../../components/header';
import Footer from '../../../components/footer';
import { BannerError } from '../../../components/banner';
import useAuth from '../../../features/hooks/useAuth';
import apiAuth from '../../../services/api/auth';
import useToggle from '../../../features/hooks/useToggle';
import { SDivider10, SDivider20, SDivider30, SDivider100 } from '../../../styles/dividerStyles';
import { SHrAuto } from '../../../styles/hrStyles';
import { SBtnPrimary } from '../../../styles/buttonStyles';

export default function Login() {
  const { t } = useTranslation();

  return (
    <>
      <Head
        title={t('login-title', { brand: `${process.env.REACT_APP_APP_NAME}` })}
        description={t('login-description', { brand: `${process.env.REACT_APP_APP_NAME}` })}
        keyword={t('login-keyword')}
        path='login'
      />
      <Header/>
      <SDivider30/>
      <Main/>
      <SDivider100/>
      <Footer/>
    </>
  );
}

const Main = () => {
  const { t } = useTranslation();

  return(
    <main className='container'>
      <h1 className="text-center">{t('login-h1')}</h1>

      <SDivider20/>
      <FormLogin/>
      <SDivider30/>

      <p className="text-center">
        {t('login-main-1')}
      </p>

      <SDivider20/>
      <SHrAuto/>
      <SDivider20/>

      <div className="text-center">
        <Link to={t('url-sign_up')}>{t('login-main-2')}</Link>
      </div>
    </main>
  );
}

const FormLogin = () => {
  const { t } = useTranslation();
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const [btnDisabled, setBtnDisabled] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [check, toggleCheck] = useToggle('persist', true);
  const [errMsg, setErrMsg] = useState('');

  const successLogin = (data) => {
    const accessToken = data.accessToken;
    const roles = data.roles;
    setAuth({ email, roles, accessToken });
    setEmail('');
    setPassword('');
    navigate(t('url-market'));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setBtnDisabled(true);
      const response = await apiAuth({email: email, password: password});
      successLogin(response.data);
    } catch (err) {
      if(!err?.response) {
        setErrMsg('Nessuna risposta dal server');
      } else if (err.response?.status === 400) {
        setErrMsg('Le tue credenziali sono errate');
      } else if (err.response?.status === 401) {
        setErrMsg('Le tue credenziali sono errate');
      } else {
        setErrMsg('Errore login');
      }
    } finally {
      setBtnDisabled(false)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <BannerError>
        {errMsg}
      </BannerError>

      <label htmlFor='email'>
        Email
        <input type="email"
          id="email"
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          maxLength="64"
          required
        />
      </label>

      <SDivider10/>

      <label htmlFor='password'>Password
        <input type="password"
          id="password"
          onChange={(e) => setPassword(e.target.value)}
          placeholder="*****"
          maxLength="32"
          required
        />
      </label>

      <SDivider10/>

      <label className="checkbox">
        <input type="checkbox"
          onChange={toggleCheck}
          checked={check}
          id="persist"
        />
        {t('login-form-3')}
      </label>

      <SDivider30/>

      <Link to={t('url-recovery_account')}>{t('login-form-4')}</Link>

      <SDivider10/>

      <div className="text-center">
        <SBtnPrimary className='width-100' disabled={btnDisabled}>
          {t('login-form-5')}
        </SBtnPrimary>
      </div>
    </form>
  );
}