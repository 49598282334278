import React, { useState, useEffect } from "react";

export default function SelectComponent({
  label,
  options,
  onChange,
  required = false,
  disabled = false
}) {
  const [value, setValue] = useState();

  useEffect(() => {
    setValue(options[0].value);
    onChange(options[0].value)
  }, [options])

  const updateValue = ({ target }) => {
    setValue(target.value);
    onChange(target.value)
  };

  return (
    <div>
      <label htmlFor={label}>{label}</label>
      <select
        id={label}
        value={value}
        onChange={updateValue}
        required={required}
        disabled={disabled}
      >
        { options.map((option, i) => (
          <option value={option.value} key={i}>
            {option.label}
          </option>
        )) }
      </select>
    </div>
  );
}