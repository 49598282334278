import styled, { keyframes }  from "styled-components";

export const SStall = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media (max-width: 1500px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const SProductCard = styled.div`
  position: relative;

  margin-bottom: 50px;
  border-radius: 8px;
  padding: 40px;
  width: 100%;

  background-color: #fff;
  box-shadow: 0 36px 28px -20px rgba(0, 0, 0, 0.20);

  animation: ${fadeIn} 0.9s;
  -webkit-animation: ${fadeIn} 0.9s;
  -moz-animation: ${fadeIn} 0.9s;
  -o-animation: ${fadeIn} 0.9s;
  -ms-animation: ${fadeIn} 0.9s;
`;

export const SProductPrice = styled.span`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -20px;
  left: -2px;

  width: 70px;
  height: 70px;

  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border-radius: 50%;
  font-size: 0.8em;
  text-transform: uppercase;
  letter-spacing: 1.4px;
`;

export const SProductImg = styled.div`
  width: 100%;
  height: 350px;

  background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  background-position: center center;
`;

export const SProductTitle = styled.strong`
  margin: 0 0 0.5em 0;
  line-height: 1.1;
`;

export const SProductAddCart = styled.div`
  position: absolute;
  bottom: -30px;
  right: 30px;
`;