import React from 'react';

import {
  SContainer,
  SButton
} from './styles';

export function PaginationNumber({ currentPage, totalPages, onChange }) {

  function handleOnClick(selectedPage) {
    onChange(selectedPage);
  };

  return (
    <SContainer>
      { currentPage > 1 &&
        <>
          <SButton onClick={() => handleOnClick(1)}>&#706;&#706;</SButton>
          <SButton onClick={() => handleOnClick(currentPage - 1)}>&#706;</SButton>
        </>
      }

      <PaginationItem position={-2} currentPage={currentPage} totalPages={totalPages} handleOnClick={handleOnClick}/>
      <PaginationItem position={-1} currentPage={currentPage} totalPages={totalPages} handleOnClick={handleOnClick}/>
      <SButton $active={true}>{currentPage}</SButton>
      <PaginationItem position={+1} currentPage={currentPage} totalPages={totalPages} handleOnClick={handleOnClick}/>
      <PaginationItem position={+2} currentPage={currentPage} totalPages={totalPages} handleOnClick={handleOnClick}/>

      { currentPage < totalPages &&
        <>
          <SButton onClick={() => handleOnClick(currentPage + 1)}>&#707;</SButton>
          <SButton onClick={() => handleOnClick(totalPages)}>&#707;&#707;</SButton>
        </>
      }
    </SContainer>
  );
}

const PaginationItem = ({ position, currentPage, totalPages, handleOnClick }) => {
  const numberPage = currentPage + position;

  if ( numberPage >= 1 && numberPage <= totalPages ) {
    return (
      <SButton onClick={() => handleOnClick(numberPage)}>{numberPage}</SButton>
    );
  } else {
    return null;
  }
}

export function PaginationStep({ currentPage, onChange }) {

  function handleOnClick(selectedPage) {
    onChange(selectedPage);
  };

  return (
    <SContainer>
      { currentPage > 1 &&
        <SButton onClick={() => handleOnClick(currentPage - 1)}>&#706;</SButton>
      }
      <SButton onClick={() => handleOnClick(currentPage + 1)}>&#707;</SButton>
    </SContainer>
  );
}