import React from "react";

import {
  SBackground,
  SWindow,
  SHeader,
  STitle,
  SClose,
  SBody,
  SFooter,
  SConfirm,
  SCancel,
}
from './styles';

import { AiOutlineClose } from "react-icons/ai";

export default function Modal({
  children,
  visibled,
  closeModal,
  title,
  confirmBtnText,
  cancelBtnText,
  onConfirm,
  onCancel
}) {
  return (
    visibled &&
    <SBackground>
      <SWindow>
        <Header
          closeModal = {closeModal}
          title = {title}
        />

        <Body
          body = {children}
        />

        <Footer
          onCancel = {onCancel}
          cancelBtnText = {cancelBtnText}
          onConfirm = {onConfirm}
          confirmBtnText = {confirmBtnText}
        />
      </SWindow>
    </SBackground>
  );
}

const Header = ({title, closeModal}) => {
  return (
    <SHeader>
      <STitle>
        {title}
      </STitle>

      <SClose type="button">
        <AiOutlineClose onClick={() => closeModal()}/>
      </SClose>
    </SHeader>
  );
}

const Body = ({body}) => {
  return (
    body && <SBody>{body}</SBody>
  );
}

const Footer = ({onCancel, cancelBtnText, onConfirm, confirmBtnText}) => {
  return (
    <SFooter>
      {
        onConfirm &&
        <SConfirm onClick={() => onConfirm()} type='button'>
          {confirmBtnText ? confirmBtnText : "Conferma"}
        </SConfirm>
      }
      {
        onCancel &&
        <SCancel onClick={() => onCancel()} type='button'>
          {cancelBtnText ? cancelBtnText : "Annulla"}
        </SCancel>
      }
    </SFooter>
  );
}