import React from "react";
import { Routes, Route } from "react-router-dom";
import i18next from 'i18next';
import { getLangCodeFromUrl } from "./features/getCodeLang";
import url from './config/url.json';

import ROLES from './config/rolesList';
import PersistLogin from './features/PersistLogin';
import RequireAuth from './features/RequireAuth';

import Home from './pages/home';
import Contact from "./pages/contact";
import PolicyPrivacy from "./pages/policyPrivacy";

import OliveOil from "./pages/productsTab/olive";
import TropicFruit from "./pages/productsTab/tropic";
import Market from "./pages/market";
import Cart from "./pages/cart";
import Login from "./pages/auth/login";
import SignUp from "./pages/auth/signUp";
import RecoveryAccount from "./pages/auth/recoveryAccount";
import DataAccount from "./pages/account/data";
import Order from "./pages/account/order";

export default function AppRoutes() {
  const languageCode = getLangCodeFromUrl();
  document.documentElement.lang = languageCode;
  i18next.changeLanguage(languageCode);

  return (
    <Routes>
      <Route index element={<Home/>} />

      { /* it */ }
      <Route path={url.it.home} element={<Home/>} />
      <Route path={url.it.contact} element={<Contact/>} />
      <Route path={url.it.privacy} element={<PolicyPrivacy/>} />
      <Route path={url.it.olive_oil} element={<OliveOil/>} />
      <Route path={url.it.tropic_fruit} element={<TropicFruit/>} />
      <Route path={url.it.market} element={<Market/>} />
      <Route path={url.it.cart} element={<Cart/>} />
      <Route path={url.it.login} element={<Login/>} />
      <Route path={url.it.sign_up} element={<SignUp/>} />
      <Route path={url.it.recovery_account} element={<RecoveryAccount/>} />

      { /* en */ }
      <Route path={url.en.home} element={<Home/>} />
      <Route path={url.en.contact} element={<Contact/>} />
      <Route path={url.en.privacy} element={<PolicyPrivacy/>} />
      <Route path={url.en.olive_oil} element={<OliveOil/>} />
      <Route path={url.en.tropic_fruit} element={<TropicFruit/>} />
      <Route path={url.en.market} element={<Market/>} />
      <Route path={url.en.cart} element={<Cart/>} />
      <Route path={url.en.login} element={<Login/>} />
      <Route path={url.en.sign_up} element={<SignUp/>} />
      <Route path={url.en.recovery_account} element={<RecoveryAccount/>} />

      { /* Require Auth */ }
      <Route element={<PersistLogin/>}>
        <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
          { /* it */ }
          <Route path={url.it.data_account} element={<DataAccount/>} />
          <Route path={url.it.order} element={<Order/>} />

          { /* en */ }
          <Route path={url.en.data_account} element={<DataAccount/>} />
          <Route path={url.en.order} element={<Order/>} />
        </Route>
      </Route>

      { /* Missing page */ }
      <Route path="/*" element={<Home/>} />
    </Routes>
  );
};