import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";

import Head from '../../../layouts/head';
import Header from '../../../components/header';
import Footer from '../../../components/footer';
import { BannerError, BannerInfo } from '../../../components/banner';
import useAxiosPrivate from '../../../features/hooks/useAxiosPrivate';
import { UserContextProvider, UserContext } from "../../../services/context/User";
import { InvoiceContextProvider, InvoiceContext } from '../../../services/context/Invoice';
import FormUser from './formUser';
import FormNewPassword from './formNewPassword';
import FormInvoice from './formInvoice';
import { SDivider10, SDivider30, SDivider50, SDivider100 } from '../../../styles/dividerStyles';
import { SHrAuto } from '../../../styles/hrStyles';
import { SBtnPrimary } from '../../../styles/buttonStyles';

export default function AccountData() {
  const { t } = useTranslation();

  return (
    <>
      <Head
        title={t('account_data-title', { brand: `${process.env.REACT_APP_APP_NAME}` })}
        path='account_data'
      />
      <Header/>
      <SDivider50/>
      <Main/>
      <SDivider100/>
      <Footer/>
    </>
  );
}

const Main = () => {
  const { t } = useTranslation();

  return(
    <main className='container'>
      <h2>{t('account_data-h1')}</h2>
      <SDivider10/>

      <Form/>
    </main>
  );
}

const Form = () => {
  return (
    <UserContextProvider>
      <InvoiceContextProvider>
        <FormContent/>
      </InvoiceContextProvider>
    </UserContextProvider>
  )
}

const FormContent = () => {
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [errMsg, setErrMsg] = useState();
  const [infoMsg, setInfoMsg] = useState();
  const {
    getUserEmail,
    setUserEmail,
    isValidUserEmail,
    setInvoicePhone,
    getInvoicePhone,
    getUserOldPassword,
    getUserPassword,
    isPasswordsMatch
  } = useContext(UserContext);
  const {
    setInvoiceFiscalCode,
    setInvoiceName,
    setInvoiceLastName,
    setInvoiceState,
    setInvoiceCity,
    setInvoiceZip,
    setInvoiceAddress,
  } = useContext(InvoiceContext);

  useEffect(() => {
    const getAccount = async () => {
      try {
        setErrMsg("");
        setBtnDisabled(true);

        const response = await axiosPrivate.get(`/account`);
        if (response.status === 200) {
          setDataAccount(response.data);
        }
      } catch (err) {
        if (!err?.response) {
          setErrMsg(t('err-server-no_response'));
        }
      } finally {
        setBtnDisabled(false);
      }
    }

    getAccount();

    function setDataAccount(data) {
      setUserEmail(data.account.email);
      setInvoicePhone(data.account.phone);
      setInvoiceFiscalCode(data.billingAddress.fiscalCode);
      setInvoiceName(data.billingAddress.name);
      setInvoiceLastName(data.billingAddress.lastName);
      setInvoiceState(data.billingAddress.state);
      setInvoiceCity(data.billingAddress.city);
      setInvoiceZip(data.billingAddress.zip);
      setInvoiceAddress(data.billingAddress.address);
    }
    // eslint-disable-next-line
  }, []);

  const handleSubmitAccount = async(e) => {
    e.preventDefault();
    if (isValidUserEmail()) {
      try {
        const response = await axiosPrivate.post(`/update-account`, {
          email: getUserEmail(),
          phone: getInvoicePhone(),
        });
        if (response.status === 200) {
          setInfoMsg(t('form_user-info-update'))
        } else {
          setErrMsg(t('err-server-account_update'));
        }
      } catch (err) {
        if(!err?.response){
          setErrMsg(t('err-server-no_response'));
        } else if (err.response?.status === 400) {
          setErrMsg(t('err-server-bad_fill'));
        }
      }
    } else {
      setErrMsg(t('err-server-bad_fill'));
    }
  }

  const handleSubmitPassword = async(e) => {
    e.preventDefault();
    if (isPasswordsMatch()) {
      try {
        const response = await axiosPrivate.post(`/update-password`, {
          oldPassword: getUserOldPassword(),
          newPassword: getUserPassword(),
        });
        if (response.status === 200) {
          setInfoMsg(t('form_user-info-update_password'))
        } else {
          setErrMsg(t('err-server-old_pass'));
        }
      } catch (err) {
        if(!err?.response){
          setErrMsg(t('err-server-no_response'));
        } else if (err.response?.status === 400) {
          setErrMsg(t('err-server-bad_fill'));
        }
      }
    } else {
      setErrMsg(t('err-server-different_passwords'));
    }
  }

  return (
    <div>
      <BannerError>{errMsg}</BannerError>
      <BannerInfo>{infoMsg}</BannerInfo>

      <form onSubmit={handleSubmitAccount} autoComplete="none">
        <FormUser/>
        <SDivider30/>
        <div className="text-center">
          <SBtnPrimary disabled={btnDisabled}>{t('account_data-update')}</SBtnPrimary>
        </div>
      </form>

      <Divider/>

      <form onSubmit={handleSubmitPassword} autoComplete="none">
        <FormNewPassword/>
        <SDivider30/>
        <div className="text-center">
          <SBtnPrimary disabled={btnDisabled}>{t('account_data-update_password')}</SBtnPrimary>
        </div>
      </form>

      <Divider/>

      <FormInvoice disabled={true}/>
      <Divider/>
    </div>
  )
}

const Divider = () => {
  return (
    <>
      <SDivider30/>
      <SHrAuto style={{background: "rgb(230, 189, 25)"}}/>
      <SDivider30/>
    </>
  )
}