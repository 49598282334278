import styled from "styled-components";

const BACKGROUND = "#fff";
const STYLE_BUTTONS = `
  border-radius: 5px;
  border: 1px solid;
  padding: 8px 10px;
  min-width: 150px;

  text-align: center;
  font-size: 1rem;
  font-family: OpenSans-SemiBold;
  text-decoration: none !important;
  cursor: pointer;

  @media (max-width: 500px) {
    min-width: 130px;
  }
`;

export const SBackground = styled.div`
  height: 100%;
  width: 100%;

  position: fixed;
  left: 0; top: 0;
  z-index: 1;
  overflow: none;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const SWindow = styled.div`
  margin: 20vh auto;
  width: 50%;

  background: ${BACKGROUND};

  @media (max-width: 1199px) {
    width: 65%;
  }

  @media (max-width: 768px) {
    width: 85%;
  }

  @media (max-width: 550px) {
    width: 99%;
  }
`;

export const SHeader = styled.header`
  display: grid;
  grid-template-columns: 1fr 25px;
  grid-template-areas: "modalTitle modalClose";

  padding: 10px;

  background: ${BACKGROUND};

  @media (max-width: 550px) {
    padding: 10px 5px;
  }
`;

export const STitle = styled.h4`
  grid-area: modalTitle;
  align-self: center;
  justify-self: center;

  font-size: 1.3rem;
  font-family: OpenSans-SemiBold;
`;

export const SClose = styled.button`
  grid-area: modalClose;
  align-self: center;
  justify-self: center;

  border: none;
  padding: 5px 0px;

  font-size: 19px;
  line-height: 0px;
  background: transparent;
  cursor: pointer;
`;

export const SBody = styled.div`
  margin: 0px;
  padding: 0px;
`;

export const SFooter = styled.footer`
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 0px 10px;
  grid-template-areas: "modalFooterLeft modalFooterRight";

  padding: 10px;

  background: ${BACKGROUND};

  @media (max-width: 550px) {
    padding: 10px 5px;
  }
`;

export const SConfirm = styled.button`
  grid-area: modalFooterRight;
  align-self: center;
  justify-self: center;

  ${STYLE_BUTTONS}

  background-color: ${({ theme }) => theme.btnBgPrimary};
  border-color: ${({ theme }) => theme.btnBorderPrimary};
  color: ${({ theme }) => theme.btnTextPrimary};

  &:hover {
    background-color: ${({ theme }) => theme.btnBgPrimaryHover};
    border-color: ${({ theme }) => theme.btnBorderPrimaryHover};
  }
`;

export const SCancel = styled.button`
  grid-area: modalFooterLeft;
  align-self: center;
  justify-self: center;

  ${STYLE_BUTTONS}

  background-color: ${({ theme }) => theme.btnBgPrimary};
  border-color: ${({ theme }) => theme.btnBorderPrimary};
  color: ${({ theme }) => theme.btnTextPrimary};

  &:hover {
    background-color: ${({ theme }) => theme.btnBgPrimaryHover};
    border-color: ${({ theme }) => theme.btnBorderPrimaryHover};
  }
`;