import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import i18InitConfig from './config/i18nInitConfig';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

import { AuthProvider } from './services/context/AuthProvider';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init(i18InitConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <AuthProvider>
      <Routes>
        <Route path="/*" element={ <App/> }/>
      </Routes>
    </AuthProvider>
  </BrowserRouter>
);