import React, { useState, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

import useAxiosPrivate from '../../features/hooks/useAxiosPrivate';
import useAuth from '../../features/hooks/useAuth';
import ROLES from '../../config/rolesList';
import { CartContext } from "../../services/context/Cart";
import { ShippingContext, ShippingContextProvider } from '../../services/context/Shipping';

import Head from '../../layouts/head';
import Header from '../../components/header';
import Footer from '../../components/footer';
import FormShipping from '../account/data/formShipping';

import { SContainer, SBtnDelete, STotalOrderRow, STotalOrderKey, STotalOrderValue } from './styles';
import { BannerError, BannerInfo } from "../../components/banner";
import { SDivider10, SDivider20, SDivider30, SDivider100 } from '../../styles/dividerStyles';
import { SHrLeft } from '../../styles/hrStyles';
import { SBtnPrimary } from '../../styles/buttonStyles';
import { STable, STbodyTd, STheadTh } from '../../styles/tableStyles';
import Counter from '../../components/counter';
import { formatCurrency } from '../../utils/string';

import { MdClose } from "react-icons/md";
import { BsPhone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";

export default function Cart() {
  const { t } = useTranslation();

  return (
    <>
      <Head
        title={t('cart-title', { brand: `${process.env.REACT_APP_APP_NAME}` })}
        path='cart'
      />
      <Header/>
      <SDivider30/>
      <ShippingContextProvider>
        <Main/>
      </ShippingContextProvider>
      <SDivider100/>
      <Footer/>
    </>
  );
}

const Main = () => {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [errMsg, setErrMsg] = useState();
  const [infoMsg, setInfoMsg] = useState();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const {
    getCart,
    isEmptyCart,
  } = useContext(CartContext);
  const {
    getShippingState,
    getShippingCountry,
    getShippingCity,
    getShippingZip,
    getShippingAddress,
  } = useContext(ShippingContext);

  const handleSubmit = async(e) => {
    e.preventDefault();
    setErrMsg();
    setBtnDisabled(true);
    try {
      if (isEmptyCart()) {
        setErrMsg("Il carrello è vuoto");
      } else {
        if (auth?.roles?.find(role => [ROLES.User].includes(role))) {
          const response = await axiosPrivate.post('/create-checkout', body());
          window.location.assign(response.data.url);
        } else {
          setInfoMsg("Prima di procedere con l'acquisto bisogna effettuare il l'accesso");
        }
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("I campi non sono stati compilati correttamente");
      }
    } finally {
      setBtnDisabled(false);
    }

    function body() {
      let body = {
        shippingAddress: {
          state: getShippingState(),
          country: getShippingCountry(),
          zip: getShippingZip(),
          city: getShippingCity(),
          address: getShippingAddress()
        },
        products: [],
      };

      for (const product of getCart()) {
        body.products = [...body.products, {
          idProduct: product.id,
          quantity: `${product.quantity}`,
        }];
      }
      return body;
    }
  }

  return (
    <main className='container'>
      <form onSubmit={handleSubmit}>
        <BannerError>
          {errMsg}
        </BannerError>
        <BannerInfo>
          {infoMsg}
        </BannerInfo>

        <SContainer>
          <TableProducts/>
          <TotalOrder btnDisabled={btnDisabled}/>
          <FormShipping/>
          <Helper/>
        </SContainer>
      </form>
    </main>
  );
}

const TableProducts = () => {
  const { t } = useTranslation();
  const { getCart, isEmptyCart } = useContext(CartContext);

  return (
    <div>
      <h2>{t('cart-h1')}</h2>
      <SDivider30/>
      <STable>
        <thead style={{textAlign: "center"}}>
          <tr>
            <STheadTh>{t('cart-product')}</STheadTh>
            <STheadTh>{t('cart-quantity')}</STheadTh>
            <STheadTh>{t('cart-price')}</STheadTh>
            <STheadTh>{t('cart-total')}</STheadTh>
            <STheadTh></STheadTh>
          </tr>
        </thead>

        <tbody>
          { !isEmptyCart() ? getCart().map((product, i) =>
              <TableRow product={product} key={i}/>
            ) :
            <tr className='text-center'>
              <td style={{padding: "12px"}} colSpan="5">
                <strong>{t('cart-empty')}</strong>
                <SDivider10/>
                <Link to={t('url-market')}>{t('cart-start_buy')}</Link>
              </td>
            </tr>
          }
        </tbody>
      </STable>
    </div>
  )
}

const TableRow = ({ product }) => {
  const { removeFromCart, updateCartItemCount } = useContext(CartContext);

  const updateQuantity = (newQuantity) => {
    updateCartItemCount(product.id, newQuantity);
  }

  return (
    <tr>
      <STbodyTd style={{textAlign: "center"}}>
        <strong>{product.name}</strong>
      </STbodyTd>
      <STbodyTd style={{textAlign: "center"}}>
        <Counter
          max={10}
          min={1}
          value={product.quantity}
          onChange={updateQuantity}
        />
      </STbodyTd>
      <STbodyTd style={{textAlign: "right"}}>
        {product.price} $
      </STbodyTd>
      <STbodyTd style={{textAlign: "right"}}>
        {formatCurrency(product.price * product.quantity)} $
      </STbodyTd>
      <STbodyTd style={{textAlign: "center"}}>
        <SBtnDelete onClick={() => removeFromCart(product.id)}>
          <MdClose/>
        </SBtnDelete>
      </STbodyTd>
    </tr>
  );
}

const TotalOrder = ({ btnDisabled }) => {
  const { t } = useTranslation();
  const {
    getCartAmount,
    getTotalCartAmount
  } = useContext(CartContext);

  return (
    <div>
      <h2>{t('cart-total_order')}</h2>
      <SDivider30/>

      <STotalOrderRow>
        <STotalOrderKey>{t('cart-total_products')}</STotalOrderKey>
        <STotalOrderValue>{formatCurrency(getCartAmount())} $</STotalOrderValue>
      </STotalOrderRow>

      <SDivider20/>
      <SHrLeft style={{background: "rgb(230, 189, 25)"}}/>
      <SDivider20/>

      <STotalOrderRow>
        <STotalOrderKey>{t('cart-total_order')}:</STotalOrderKey>
        <STotalOrderValue>{formatCurrency(getTotalCartAmount())} $</STotalOrderValue>
      </STotalOrderRow>

      <SDivider20/>

      <SBtnPrimary type="submit" disabled={btnDisabled} style={{width: "100%"}}>{t('cart-buy')}</SBtnPrimary>
    </div>
  )
}

const Helper = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h4>{t('cart-help')}</h4>
      <SDivider20/>

      <p>
        <BsPhone style={{fontSize: "20px", marginRight: "8px"}}/>
        <a href={`tel:` + process.env.REACT_APP_CONTACT_PHONE}>
          {process.env.REACT_APP_CONTACT_PHONE}
        </a>

        <br/><br/>

        <AiOutlineMail style={{fontSize: "20px", marginRight: "8px"}}/>
        <a href={`mailto:` + process.env.REACT_APP_CONTACT_EMAIL}>
          {process.env.REACT_APP_CONTACT_EMAIL}
        </a>
      </p>
    </div>
  );
}