import { createContext, useState } from "react";
import { isValidPhoneNumber } from 'react-phone-number-input'

import { isValidEmail, isValidPassword } from '../../utils/regex';

export const UserContext = createContext(null);

export const UserContextProvider = (props) => {
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [phone, setPhone] = useState('');
  const [validPhone, setValidPhone] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [validPassword, setValidPassword] = useState(false);
  const [matchPassword, setMatchPassword] = useState('');

  const setUserEmail = (email) => {
    setEmail(email);
    isValidEmail(email) ? setValidEmail(true) : setValidEmail(false);
  }
  const getUserEmail = () => {
    return email;
  }
  const isValidUserEmail = () => {
    return validEmail;
  }

  const setInvoicePhone = (phone) => {
    setPhone(phone);
    if (phone) {
      isValidPhoneNumber(phone) ? setValidPhone(true) : setValidPhone(false);
    } else {
      setValidPhone(false);
    }
  }
  const getInvoicePhone = () => {
    return phone;
  }
  const isValidUserPhone = () => {
    return validPhone;
  }

  const setUserOldPassword = (password) => {
    setOldPassword(password);
  }
  const getUserOldPassword = () => {
    return oldPassword;
  }

  const setUserPassword = (password) => {
    setPassword(password);
    isValidPassword(password) ? setValidPassword(true) : setValidPassword(false);
  }
  const getUserPassword = () => {
    return password;
  }
  const isValidUserPassword = () => {
    return validPassword;
  }
  const setUserMatchPassword = (matchPassword) => {
    setMatchPassword(matchPassword);
  }
  const isPasswordsMatch = () => {
    return password === matchPassword ? true : false;
  }

  const isValidUserData = () => {
    return validEmail && validPassword && isPasswordsMatch() && validPhone ? true : false;
  }

  const contextValue = {
    setUserEmail,
    getUserEmail,
    isValidUserEmail,
    setInvoicePhone,
    getInvoicePhone,
    isValidUserPhone,
    setUserOldPassword,
    getUserOldPassword,
    setUserPassword,
    getUserPassword,
    isValidUserPassword,
    setUserMatchPassword,
    isPasswordsMatch,
    isValidUserData
  };

  return (
    <UserContext.Provider value={contextValue}>
      {props.children}
    </UserContext.Provider>
  );
};