import React from 'react';
import { useTranslation } from "react-i18next";

import Head from '../../layouts/head';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { SGridCard, SCard, SCardImg, SCardTitle, SCardBody } from './styles';
import { SMiniHero } from '../../styles/heroStyle';
import { SDivider10, SDivider20, SDivider50, SDivider100 } from '../../styles/dividerStyles';
import { SImgSideMd, SSide, SSideContainer } from '../../styles/sidebySideStyle';
import { SHrFull, SHrAuto } from '../../styles/hrStyles';

const imgHero = "tropical/hero-tropic_fruit.webp";
const imgPapaya = "tropical/papaya.webp";
const imgAvocado = "tropical/avocado.webp";
const imgMango = "tropical/mango.webp";
const imgSide1 = "tropical/avocado_tree.webp";
const imgSide2 = "tropical/papaya_tree.webp";
const imgSide3 = "tropical/mango_tree.webp";

export default function TropicFruit() {
  const { t } = useTranslation();

  return (
    <>
      <Head
        title={t('tropic_fruit-title', { brand: `${process.env.REACT_APP_APP_NAME}` })}
        description={t('tropic_fruit-description')}
        keyword={t('tropic_fruit-keyword')}
        path='tropic_fruit'
      />
      <Hero/>
      <SDivider50/>
      <Main/>
      <SDivider100/>
      <Footer/>
    </>
  );
}

const Hero = () => {
  return (
    <SMiniHero style={{backgroundImage: `url('/assets/img/${imgHero}')`}}>
      <Header/>
    </SMiniHero>
  )
}

const Main = () => {
  return (
    <main className='container'>
      <CardFruit/>
      <Divider/>
      <Section1/>
      <SDivider20/>
      <Section2/>
      <SDivider20/>
      <Section3/>
    </main>
  );
}

const CardFruit = () => {
  const { t } = useTranslation();

  return (
    <SGridCard>
      <SCard>
        <SCardImg style={{backgroundImage: `url('/assets/img/${imgPapaya}')`}}/>
        <SCardTitle>Papaya</SCardTitle>
        <SCardBody>
          {t('tropic_fruit-card_papaya')}
        </SCardBody>
      </SCard>

      <SCard>
        <SCardImg style={{backgroundImage: `url('/assets/img/${imgAvocado}')`}}/>
        <SCardTitle>Avocado</SCardTitle>
        <SCardBody>
          {t('tropic_fruit-card_avocado')}
        </SCardBody>
      </SCard>

      <SCard>
        <SCardImg style={{backgroundImage: `url('/assets/img/${imgMango}')`}}/>
        <SCardTitle>Mango</SCardTitle>
        <SCardBody>
          {t('tropic_fruit-card_mango')}
        </SCardBody>
      </SCard>
    </SGridCard>
  )
}

const Section1 = () => {
  const { t } = useTranslation();

  return (
    <SSideContainer className="clearfix">
      <SImgSideMd style={{backgroundImage: `url('/assets/img/${imgSide1}')`}}/>

      <SSide>
        <div style={{padding: "20px", paddingBottom: "0px"}}>
          <h3>{t('tropic_fruit-section-1-1')}</h3>
          <DividerShort/>

          <p>
            {t('tropic_fruit-section-1-2')}
            <br/><br/>
            {t('tropic_fruit-section-1-3')}
            <br/><br/>
            {t('tropic_fruit-section-1-4')}
          </p>
        </div>
      </SSide>
    </SSideContainer>
  )
}

const Section2 = () => {
  const { t } = useTranslation();

  return (
    <SSideContainer className="clearfix">
      <SSide>
        <div style={{padding: "20px", paddingBottom: "0px"}}>
          <h3>{t('tropic_fruit-section-2-1')}</h3>
          <DividerShort/>

          <p>
            {t('tropic_fruit-section-2-2')}<br/>
            {t('tropic_fruit-section-2-3')}
          </p>
        </div>
      </SSide>

      <SImgSideMd style={{backgroundImage: `url('/assets/img/${imgSide2}')`}}/>
    </SSideContainer>
  )
}

const Section3 = () => {
  const { t } = useTranslation();

  return (
    <SSideContainer className="clearfix">
      <SImgSideMd style={{backgroundImage: `url('/assets/img/${imgSide3}')`}}/>

      <SSide>
        <div style={{padding: "20px", paddingBottom: "0px"}}>
          <h3>{t('tropic_fruit-section-3-1')}</h3>
          <DividerShort/>

          <p>
            {t('tropic_fruit-section-3-2')}<br/>
            {t('tropic_fruit-section-3-3')}
          </p>
        </div>
      </SSide>
    </SSideContainer>
  )
}

const Divider = () => {
  return (
    <>
      <SDivider50/>
      <SHrAuto style={{background: "rgb(230, 189, 25)"}}/>
      <SDivider10/>
      <SHrFull style={{background: "rgb(107, 142, 35)"}}/>
      <SDivider10/>
      <SHrAuto style={{background: "rgb(230, 189, 25)"}}/>
      <SDivider50/>
    </>
  )
}

const DividerShort = () => {
  return (
    <>
      <SDivider20/>
      <SHrAuto style={{background: "rgb(230, 189, 25)"}}/>
      <SDivider20/>
    </>
  )
}