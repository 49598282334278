import styled from "styled-components";

const COMMON_RULE = `
margin: 10px 0px;
border: 1px solid;
padding: 13px 10px;
`;

export const SErrorBanner = styled.div`
  ${COMMON_RULE}

  color: #D8000C;
  background-color: #FFBABA;
`;

export const SInfoBanner = styled.div`
  ${COMMON_RULE}

  color: #00529B;
  background-color: #BDE5F8;
`;

export const SSuccessBanner = styled.div`
  ${COMMON_RULE}

  color: #4F8A10;
  background-color: #DFF2BF;
`;

export const SWarningBanner = styled.div`
  ${COMMON_RULE}

  color: #9F6000;
  background-color: #FEEFB3;
`;