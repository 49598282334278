import styled, { keyframes } from "styled-components";

export const SGridCard = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const SCard = styled.div`
  margin: 10px 0px;
  border-radius: 20px;

  background-color: ${({ theme }) => theme.bg2};

  animation: ${fadeIn} 0.9s;
  -webkit-animation: ${fadeIn} 0.9s;
  -moz-animation: ${fadeIn} 0.9s;
  -o-animation: ${fadeIn} 0.9s;
  -ms-animation: ${fadeIn} 0.9s;
`;

export const SCardImg = styled.div`
  border-radius: 20px;
  height: 200px;

  background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  background-position: center center;
`;

export const SCardTitle = styled.h3`
  padding: 15px;

  overflow: hidden;
  font-weight: bold;
  font-size: 30px;
  color: #FFF;
`;

export const SCardBody = styled.p`
  padding: 15px;
  color: #FFF;
`;
