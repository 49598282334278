import styled from "styled-components";

export const SHrAuto = styled.hr`
  width: 85px;
  margin: 0px auto;
  background: ${({ theme }) => theme.bg3};
`;

export const SHrSmall = styled.hr`
  width: 30px;
  margin: 0px auto;
  background: ${({ theme }) => theme.bg3};
`;

export const SHrFull = styled.hr`
  margin: 0px 0px;
  background: ${({ theme }) => theme.bg3};
`;

export const SHrLeft = styled.hr`
  width: 85px;
  margin: 0px 0px;
  background: ${({ theme }) => theme.bg3};
`;