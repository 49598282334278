import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

import { InvoiceContext } from "../../../services/context/Invoice";
import { SContainerForm, SGridContainer } from './styles';
import { SDivider10, SDivider20 } from '../../../styles/dividerStyles';
import SelectComponent from "../../../components/select";

import STATEOPTIONS from "../../../config/stateList";
import COUNTRYUSOPTIONS from "../../../config/countryUSList";
import COUNTRYITOPTIONS from "../../../config/countryITList";

export default function FormInvoice({ disabled = false }) {
  const { t } = useTranslation();
  const {
    setInvoiceFiscalCode,
    getInvoiceFiscalCode,
    setInvoiceName,
    getInvoiceName,
    setInvoiceLastName,
    getInvoiceLastName,
    setInvoiceState,
    getInvoiceState,
    setInvoiceCountry,
    getInvoiceCountry,
    setInvoiceCity,
    getInvoiceCity,
    setInvoiceZip,
    getInvoiceZip,
    setInvoiceAddress,
    getInvoiceAddress
  } = useContext(InvoiceContext);

  const changeState = (state) => {
    setInvoiceState(state);
  }

  const changeCountry = (country) => {
    setInvoiceCountry(country);
  }

  return (
    <SContainerForm>
      <h4>{t('form_invoice-h1')}</h4>
      <SDivider20/>

      <SGridContainer>
        <div>
          <label htmlFor='fiscalCode'>{t('form_invoice-cf')}</label>
          <input
            type="text"
            id="fiscalCode"
            value={getInvoiceFiscalCode()}
            onChange={(e) => setInvoiceFiscalCode(e.target.value)}
            placeholder={t('form_invoice-cf')}
            maxLength="16"
            required
            disabled={disabled}
          />
        </div>

        <div>
          <label htmlFor='name'>{t('form_invoice-name')}</label>
          <input
            type="text"
            id="name"
            value={getInvoiceName()}
            onChange={(e) => setInvoiceName(e.target.value)}
            placeholder={t('form_invoice-name')}
            maxLength="32"
            required
            disabled={disabled}
          />
        </div>

        <div>
          <label htmlFor='lastName'>{t('form_invoice-last_name')}</label>
          <input
            type="text"
            id="lastName"
            value={getInvoiceLastName()}
            onChange={(e) => setInvoiceLastName(e.target.value)}
            placeholder={t('form_invoice-last_name')}
            maxLength="16"
            required
            disabled={disabled}
          />
        </div>
      </SGridContainer>

      <SDivider20/>

      <SGridContainer>
        <SelectComponent
          label={t('form_invoice-state')}
          options={STATEOPTIONS}
          onChange={changeState}
          required={true}
          disabled={disabled}
        />

        <SelectCountry
          state={getInvoiceState}
          changeCountry={changeCountry}
          required={true}
          disabled={disabled}
        />

        <div>
          <label htmlFor="city">{t('form_invoice-city')}</label>
          <input
            type="text"
            id="city"
            value={getInvoiceCity()}
            onChange={(e) => setInvoiceCity(e.target.value)}
            placeholder={t('form_invoice-city')}
            maxLength="32"
            required
            disabled={disabled}
          />
        </div>

        <div>
          <label htmlFor='zip'>ZIP / CAP</label>
          <input
            type="text"
            id="zip"
            value={getInvoiceZip()}
            onChange={(e) => setInvoiceZip(e.target.value)}
            placeholder="ZIP / CAP"
            maxLength="8"
            required
            disabled={disabled}
          />
        </div>

        <div>
          <label htmlFor='address'>{t('form_invoice-address')}</label>
          <input
            type="text"
            id="address"
            value={getInvoiceAddress()}
            onChange={(e) => setInvoiceAddress(e.target.value)}
            placeholder={t('form_invoice-address')}
            maxLength="64"
            required
            disabled={disabled}
          />
        </div>
      </SGridContainer>

      <SDivider10/>
    </SContainerForm>
  );
}

const SelectCountry = ({ state, changeCountry, required, disabled }) => {
  const { t } = useTranslation();
  const [country, setCountry] = useState();
  const [options, setOptions] = useState();

  useEffect(() => {
    switch (state()) {
      case 'US':
        setOptions(COUNTRYUSOPTIONS);
      break;
      case 'IT':
        setOptions(COUNTRYITOPTIONS)
      break;
      default:
        setOptions(COUNTRYITOPTIONS);
      break;
    }
  }, [state])

  useEffect(() => {
    changeCountry(country)

    // eslint-disable-next-line
  }, [country])

  const onChange = (value) => {
    setCountry(value);
  }

  return (
    options &&
    <SelectComponent
      label={t('form_invoice-country')}
      options={options}
      onChange={onChange}
      required={required}
      disabled={disabled}
    />
  );
}