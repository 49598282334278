import { Link } from "react-router-dom";
import styled from "styled-components";

export const SHeaderBar = styled.header`
  display: grid;
  grid-template-areas: "logo nav";

  margin: auto;
  max-width: 1000px;

  font-family: 'Jost-Regular';
  background-color: ${({ theme }) => theme.headerBackground};
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */

  @media (max-width: 700px) {
    grid-template-areas: "logo fabar" "nav nav";
  }
`;

export const SAreaLogo = styled.div`
  display: grid;
  grid-area: logo;
  grid-template-columns: repeat(8, auto);
  justify-items: start;

  line-height: 0 !important;
`;

export const SLogo = styled(Link)`
  line-height: 0 !important;
`;

export const SLogoImg = styled.img`
  height: 70px;

  @media (max-width: 768px) {
    height: 60px;
  }
`;

export const SNavBar = styled.nav`
  display: grid;
  grid-area: nav;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  justify-items: center;

  @media (max-width: 700px) {
    display: ${({ $isopen }) => (!$isopen ? `none` : `block`)};
    grid-template-rows: repeat(5, auto);
    grid-template-columns: none;

    max-height: 225px;
    overflow: auto;
    background-color: ${({ theme }) => theme.headerBackground};
    z-index: 2;
  }
`;

export const SNavMenuLi = styled.li`
  display: inline-block;
  padding: 0px;
  width: 100%;

  text-align: center;
  font-size: 17px;
`;

export const SNavMenuA = styled(Link)`
  display: block;
  padding: 22px 0px;
  color: ${({ theme }) => theme.headerColor};

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 700px) {
    width: 100%;
  }
`;

export const SFaBar = styled.button`
  display: none;
  grid-area: fabar;
  justify-self: end;

  border: none;
  padding: 10px;

  font-size: 35px;
  line-height: 0 !important;
  background: none;
  cursor: pointer;

  @media (max-width: 700px) {
    display: inline;
  }
`;

export const SMenuLang = styled.button`
  border-width: 0px;

  color: ${({ theme }) => theme.headerColor};
  background-color: transparent;
  font-size: 35px;
  line-height: 0px !important;
  cursor: pointer;
`;

export const SMenuLangUl = styled.ul`
  display: ${({ $isOpen }) => (!$isOpen ? `none` : `block`)};
  position: absolute;
  top: 60px;
  z-index: 1;

  background-color: ${({ theme }) => theme.headerBackground};
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  list-style-type: none;
  margin-block-start: 0em;
  margin-block-end: 0em;
  padding-inline-start: 0px;
`;

export const SMenuLangButton = styled.a`
  display: flex;
  border: none;
  padding: 9px;

  background-color: transparent;
  align-items: center;
  color: ${({ theme }) => theme.headerColor};
  font-size: 15px;
  cursor: pointer;
`;

export const SMenuLangSpan = styled.span`
  margin-left: 8px;
`;

export const SMenuAccount = styled.ul`
  display: ${({ $isOpen }) => (!$isOpen ? `none` : `block`)};
  position: absolute;
  z-index: 1;

  background-color: ${({ theme }) => theme.headerBackground};
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  list-style-type: none;
  margin-block-start: 0em;
  margin-block-end: 0em;
  padding-inline-start: 0px;

  @media (max-width: 700px) {
    display: none;
  }
`;

export const SMenuAccountButton = styled(Link)`
  display: flex;
  border: none;
  padding: 9px;

  background-color: transparent;
  align-items: center;
  color: ${({ theme }) => theme.headerColor};
  font-size: 17px;
  cursor: pointer;

  @media (max-width: 700px) {
    display: none;
  }
`;

export const SNavDropdown = styled.div`
  display: block;

  @media (max-width: 700px) {
    display: none;
  }
`;

export const SNavNotDropdown = styled.div`
  display: none;

  @media (max-width: 700px) {
    display: block;

    text-transform: uppercase;
  }
`;