import React from 'react';
import { useTranslation } from "react-i18next";

import Head from '../../layouts/head';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { SGridCard, SCard, SCardImg, SCardTitle, SCardBody } from './styles';
import { SMiniHero } from '../../styles/heroStyle';
import { SDivider10, SDivider20, SDivider50, SDivider100 } from '../../styles/dividerStyles';
import { SImgSideMd, SSide, SSideContainer } from '../../styles/sidebySideStyle';
import { SHrFull, SHrAuto } from '../../styles/hrStyles';

const imgHero = "olive/olive.webp";
const imgOlive1 = "olive/nocellara.webp";
const imgOlive2 = "olive/biancolilla.webp";
const imgOlive3 = "olive/cerasuola.webp";
const imgSide1 = "olive/side-oil-1.webp";
const imgSide2 = "olive/side-oil-2.webp";

export default function OliveOil() {
  const { t } = useTranslation();

  return (
    <>
      <Head
        title={t('olive_oil-title', { brand: `${process.env.REACT_APP_APP_NAME}` })}
        description={t('olive_oil-description')}
        keyword={t('olive_oil-keyword')}
        path='olive_oil'
      />
      <Hero/>
      <SDivider50/>
      <Main/>
      <SDivider100/>
      <Footer/>
    </>
  );
}

const Hero = () => {
  return (
    <SMiniHero style={{backgroundImage: `url('/assets/img/${imgHero}')`}}>
      <Header/>
    </SMiniHero>
  )
}

const Main = () => {
  return (
    <main className='container'>
      <TypeOlive/>
      <Divider/>
      <Section1/>
      <SDivider20/>
      <Section2/>
    </main>
  );
}

const TypeOlive = () => {
  const { t } = useTranslation();

  return (
    <SGridCard>
      <SCard>
        <SCardImg style={{backgroundImage: `url('/assets/img/${imgOlive1}')`}}/>
        <SCardTitle>Nocellara del Belice</SCardTitle>
        <SCardBody>
          {t('olive_oil-nocellara')}
        </SCardBody>
      </SCard>

      <SCard>
        <SCardImg style={{backgroundImage: `url('/assets/img/${imgOlive2}')`}}/>
        <SCardTitle>Biancolilla</SCardTitle>
        <SCardBody>
          {t('olive_oil-biancolilla')}
        </SCardBody>
      </SCard>

      <SCard>
        <SCardImg style={{backgroundImage: `url('/assets/img/${imgOlive3}')`}}/>
        <SCardTitle>Cerasuola</SCardTitle>
        <SCardBody>
          {t('olive_oil-cerasuola')}
        </SCardBody>
      </SCard>
    </SGridCard>
  )
}

const Section1 = () => {
  const { t } = useTranslation();

  return (
    <SSideContainer className="clearfix">
      <SImgSideMd style={{backgroundImage: `url('/assets/img/${imgSide1}')`}}/>

      <SSide>
        <div style={{padding: "20px", paddingBottom: "0px"}}>
          <h3>{t('olive_oil-section-1-1')}</h3>
          <DividerShort/>

          <p>
            {t('olive_oil-section-1-2')}
            <br/><br/>
            {t('olive_oil-section-1-3')}
          </p>
        </div>
      </SSide>
    </SSideContainer>
  )
}

const Section2 = () => {
  const { t } = useTranslation();

  return (
    <SSideContainer className="clearfix">
      <SSide>
        <div style={{padding: "20px", paddingBottom: "0px"}}>
          <h3>{t('olive_oil-section-2-1')}</h3>
          <DividerShort/>

          <p>
            {t('olive_oil-section-2-2')}
          </p>
        </div>
      </SSide>

      <SImgSideMd style={{backgroundImage: `url('/assets/img/${imgSide2}')`}}/>
    </SSideContainer>
  )
}

const Divider = () => {
  return (
    <>
      <SDivider50/>
      <SHrAuto style={{background: "rgb(230, 189, 25)"}}/>
      <SDivider10/>
      <SHrFull style={{background: "rgb(107, 142, 35)"}}/>
      <SDivider10/>
      <SHrAuto style={{background: "rgb(230, 189, 25)"}}/>
      <SDivider50/>
    </>
  )
}

const DividerShort = () => {
  return (
    <>
      <SDivider20/>
      <SHrAuto style={{background: "rgb(230, 189, 25)"}}/>
      <SDivider20/>
    </>
  )
}