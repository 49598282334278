import axios from "./axios";

export default async function apiAuth(credential) {
  return await axios.post('/auth',
    JSON.stringify(credential),
    {
      withCredentials: true,
      headers: { 'Content-Type': 'application/json' }
    }
  );
}