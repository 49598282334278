import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import url from '../config/url.json';

import logo from '../assets/img/logo.png';

export default function Head({
  title,
  description,
  keyword,
  path
}) {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={title}/>
        <meta name="description" content={description}/>
        <meta name="keyword" content={keyword}/>
        <link name="canonical" href={`${process.env.REACT_APP_DOMAIN}/${url.it[path]}`}/>

        { /* Alternate language */ }
        <link rel="alternate" hreflang="en" href={`${process.env.REACT_APP_DOMAIN}/${url.en[path]}`}/>
        <link rel="alternate" hreflang="it" href={`${process.env.REACT_APP_DOMAIN}/${url.it[path]}`}/>
        <link rel="alternate" hreflang="x-default" href={`${process.env.REACT_APP_DOMAIN}/${url.en[path]}`}/>

        { /* Open Graph protocol */ }
        <meta property="og:site_name" content={process.env.REACT_APP_APP_NAME}/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content={`${process.env.REACT_APP_DOMAIN}/${url.en[path]}`}/>
        <meta property="og:title" content={title}/>
        <meta property="og:image" content={logo}/>
      </Helmet>
    </HelmetProvider>
  );
}