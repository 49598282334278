import { createContext, useState } from "react";

export const InvoiceContext = createContext(null);

export const InvoiceContextProvider = (props) => {
  const [fiscalCode, setFiscalCode] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [address, setAddress] = useState('');

  const setInvoiceFiscalCode = (fiscalCode) => {
    setFiscalCode(fiscalCode);
  }
  const getInvoiceFiscalCode = () => {
    return fiscalCode;
  }

  const setInvoiceName = (name) => {
    setFirstName(name);
  }
  const getInvoiceName = () => {
    return firstName;
  }

  const setInvoiceLastName = (lastName) => {
    setLastName(lastName);
  }
  const getInvoiceLastName = () => {
    return lastName;
  }

  const setInvoiceState = (state) => {
    setState(state);
  }
  const getInvoiceState = () => {
    return state;
  }

  const setInvoiceCountry = (country) => {
    setCountry(country);
  }
  const getInvoiceCountry = () => {
    return country;
  }

  const setInvoiceCity = (city) => {
    setCity(city);
  }
  const getInvoiceCity = () => {
    return city;
  }

  const setInvoiceZip = (zip) => {
    setZip(zip);
  }
  const getInvoiceZip = () => {
    return zip;
  }

  const setInvoiceAddress = (address) => {
    setAddress(address);
  }
  const getInvoiceAddress = () => {
    return address;
  }

  const contextValue = {
    setInvoiceFiscalCode,
    getInvoiceFiscalCode,
    setInvoiceName,
    getInvoiceName,
    setInvoiceLastName,
    getInvoiceLastName,
    setInvoiceState,
    getInvoiceState,
    setInvoiceCountry,
    getInvoiceCountry,
    setInvoiceCity,
    getInvoiceCity,
    setInvoiceZip,
    getInvoiceZip,
    setInvoiceAddress,
    getInvoiceAddress
  };

  return (
    <InvoiceContext.Provider value={contextValue}>
      {props.children}
    </InvoiceContext.Provider>
  );
};