import styled from "styled-components";

export const SHero = styled.section`
  height: 100vh;

  background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  background-position: center center;
`;

export const SHeroH1 = styled.h1`
  color: #000;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-family: 'Rakkas-Regular';
`;

export const SHeroH2 = styled.h2`
  color: #000;
  font-size: 1.9rem;
`;

export const SMiniHero = styled.section`
  min-height: 300px;

  background-size: cover;
  background-position: center center;
  -o-background-size: cover;
  -moz-background-size: cover;
`;

export const SMiniHeroH1 = styled.h1`
  text-transform: uppercase;
  text-align: center;
`;

export const SHeroBanner = styled.div`
  padding: 10px;
  width: 100%;

  background-color: rgb(255,255,255, 0.7);
`;

export const SParallax = styled.div`
  height: 450px;

  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  @supports (-webkit-touch-callout: none) {
    background-attachment: inherit;
  }
`;