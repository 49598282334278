const STRING_TEXT = /^[a-zA-Z0-9\s,.]{0,}$/;
const TEXT = /^[a-zA-Z\s,.]{1,}$/;
const EMAIL = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
const PASSWORD = /^.{8,24}$/;
const AMOUNT = /^(\d+(?:[.,]\d{1,2})?)$/;

const PHONE_NUMBER = /^[+0-9\s-]{0,20}$/;
const COUNTRY_CODE = /^[A-Z\s]{2}$/;
const STATE_CODE = /^[A-Z\s]{2}$/;
const ZIPCODE = /^[a-zA-Z0-9\s]{5}$/;

const PARTITA_IVA = /^[\d]{11}$/;

export function isValidString(arg) {
  return STRING_TEXT.test(arg);
}

export function isValidText(arg) {
  return TEXT.test(arg);
}

export function isValidPhoneNumber(arg) {
  return PHONE_NUMBER.test(arg);
}

export function isValidEmail(arg) {
  return EMAIL.test(arg);
}

export function isValidPassword(arg) {
  return PASSWORD.test(arg);
}

export function isValidAmount(arg) {
  return AMOUNT.test(arg);
}

export function isValidPartitaIVA(arg) {
  return PARTITA_IVA.test(arg);
}

export function isValidCountryCode(arg) {
  return COUNTRY_CODE.test(arg);
}

export function isValidStateCode(arg) {
  return STATE_CODE.test(arg);
}

export function isValidZipCode(arg) {
  return ZIPCODE.test(arg);
}