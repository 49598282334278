import React, { useEffect } from 'react';
import './cookieBanner.css';
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import CookieConsent, { getCookieConsentValue, Cookies } from "react-cookie-consent";
import { useTranslation } from "react-i18next";

function CookieBanner(){
  const { t } = useTranslation();

  const handleAcceptCookie = () => {
    if (process.env.NODE_ENV === "production" && process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }
  }

  const handleDeclineCookie = () => {
    removeGoogleAnalyticsCookies();

    function removeGoogleAnalyticsCookies() {
      Cookies.remove("_ga");
      Cookies.remove("_gat");
      Cookies.remove("_gid");
    }
  }

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);

  return (
    <CookieConsent
      location="bottom"
      cookieName="CookieConsent"
      SameSite="None"
      cookieSecurity={true}

      onAccept={handleAcceptCookie}
      buttonClasses="btn-cookie-accept"
      buttonText={t('cookie-btn-accept')}

      enableDeclineButton
      declineButtonClasses="btn-cookie-decline"
      declineButtonText={t('cookie-btn-decline')}
      onDecline={handleDeclineCookie}
      >
      {t('cookie-info_1')} <Link style={{color:"white", textDecorationLine:"underline"}} to={t('url-privacy')}>{t('cookie-info_2')}</Link> {t('cookie-info_3')}
    </CookieConsent>
  );
}

export default CookieBanner;