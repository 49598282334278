import React from "react";

import {
  SErrorBanner,
  SInfoBanner,
  SSuccessBanner,
  SWarningBanner
} from "./styles.js";

export function BannerError({children}) {
  return (
    children &&
    <SErrorBanner>
      {children}
    </SErrorBanner>
  );
};

export function BannerInfo({children}) {
  return (
    children &&
    <SInfoBanner>
      {children}
    </SInfoBanner>
  );
};

export function BannerSuccess({children}) {
  return (
    children &&
    <SSuccessBanner>
      {children}
    </SSuccessBanner>
  );
};

export function BannerWarning({children}) {
  return (
    children &&
    <SWarningBanner>
      {children}
    </SWarningBanner>
  );
};