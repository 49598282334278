import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

import { UserContext } from "../../../services/context/User";
import { SErrorBanner } from "../../../components/banner/styles";
import { SContainerForm, SGridContainerTwo } from './styles';
import { SDivider20 } from '../../../styles/dividerStyles';

export default function FormUser() {
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState("");
  const {
    getUserEmail,
    setUserEmail,
    isValidUserEmail,
    setInvoicePhone,
    getInvoicePhone,
    isValidUserPhone
  } = useContext(UserContext);

  useEffect(() => {
    setPhoneNumber(getInvoicePhone());

    // eslint-disable-next-line
  }, [getInvoicePhone]);

  useEffect(() => {
    setInvoicePhone(phoneNumber)

    // eslint-disable-next-line
  }, [phoneNumber]);

  return (
    <SContainerForm>
      <h4>{t('form_user-h1')}</h4>
      <SDivider20/>

      <SGridContainerTwo>

        <div>
          <label htmlFor='phone'>{t('form_user-phone')}</label>
          <PhoneInput
            id="phone"
            placeholder={t('form_user-phone')}
            value={phoneNumber}
            onChange={setPhoneNumber}
            required
            aria-invalid={isValidUserPhone() ? "false" : "true"}
            aria-describedby="phonenote"/>
          { getInvoicePhone() && !isValidUserPhone() &&
            <SErrorBanner id="phonenote">
              <p>{t('form_user-error-phone')}</p>
            </SErrorBanner>
          }
        </div>

        <div>
          <label htmlFor='email'>{t('form_user-email')}</label>
          <input
            type="email"
            id="email"
            value={getUserEmail()}
            onChange={(e) => setUserEmail(e.target.value)}
            placeholder={t('form_user-email')}
            maxLength="64"
            autoComplete="off"
            required
            aria-invalid={isValidUserEmail() ? "false" : "true"}
            aria-describedby="emailnote"
          />
          { getUserEmail() && !isValidUserEmail() &&
            <SErrorBanner id="emailnote">
              <p>{t('form_user-error-email')}</p>
            </SErrorBanner>
          }
        </div>
      </SGridContainerTwo>
    </SContainerForm>
  );
}