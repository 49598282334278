import React from 'react';
import { useTranslation } from "react-i18next";

import Head from '../layouts/head';
import Header from '../components/header';
import Footer from '../components/footer';
import { SDivider20, SDivider100 } from '../styles/dividerStyles';
import { SHrAuto } from '../styles/hrStyles';

export default function PolicyPrivacy() {
  const { t } = useTranslation();

  return (
    <>
      <Head
        title={t('privacy-title', { brand: `${process.env.REACT_APP_APP_NAME}` })}
        path='privacy'
      />
      <Header/>
      <SDivider100/>
      <Main/>
      <SDivider100/>
      <Footer/>
    </>
  );
}

const Main = () => {
  return (
    <main className="container">
      <Section1/>
      <Divider/>

      <Section2/>
      <Divider/>

      <Section3/>
      <SDivider20/>

      <Section4/>
      <SDivider20/>

      <Section5/>
      <SDivider20/>

      <Section6/>
      <SDivider20/>

      <Section7/>
      <SDivider20/>

      <Section8/>
      <Divider/>

      <Section9/>
    </main>
  )
}

const Section1 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h1 className="text-center">{t('privacy-section_1-1')}</h1>
      <h3>{t('privacy-section_1-2')}</h3>
      <p>{t('privacy-section_1-3', { brand: `${process.env.REACT_APP_APP_NAME}`, address: `${process.env.REACT_APP_CITY_ADDRESS}`, zip: `${process.env.REACT_APP_ZIP}`, city: `${process.env.REACT_APP_CITY}`, state: `${process.env.REACT_APP_STATE}`, phoneNumber: `${process.env.REACT_APP_CONTACT_PHONE}` })}</p><br/>
      <p>{t('privacy-section_1-4')}</p><br/>
      <p>{t('privacy-section_1-5')}</p>
    </>
  );
}

const Section2 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t('privacy-section_2-1')}</h3>
      <p>{t('privacy-section_2-2')}</p><br/>
      <p>{t('privacy-section_2-3')}</p><br/>
      <p>{t('privacy-section_2-4')}</p><br/>
      <p>{t('privacy-section_2-5')}</p>
    </>
  );
}

const Section3 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t('privacy-section_3-1')}</h3>
      <p>{t('privacy-section_3-2')}</p>
    </>
  );
}

const Section4 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t('privacy-section_4-1')}</h3>
      <h4>{t('privacy-section_4-2')}</h4>
      <p>{t('privacy-section_4-3')}</p><br/>
      <p>{t('privacy-section_4-4')}</p><br/>
      <p>{t('privacy-section_4-5')}</p><br/>
      <p>{t('privacy-section_4-6')}</p>
    </>
  );
}

const Section5 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4>{t('privacy-section_5-1')}</h4>
      <p>{t('privacy-section_5-2')}</p>
    </>
  );
}

const Section6 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4>{t('privacy-section_6-1')}</h4>
      <p>{t('privacy-section_6-2')}</p>
    </>
  );
}

const Section7 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4>{t('privacy-section_7-1')}</h4>
      <p>{t('privacy-section_7-2')}</p>
    </>
  );
}

const Section8 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4>{t('privacy-section_8-1')}</h4>
      <p>{t('privacy-section_8-2')}</p>
    </>
  );
}

const Section9 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t('privacy-section_9-1')}</h3>
      <p>{t('privacy-section_9-2')}</p><br/>
      <p>{t('privacy-section_9-3')}</p><br/>
      <p>{t('privacy-section_9-4')}</p><br/>
      <p>{t('privacy-section_9-5')}</p><br/>
      <p>{t('privacy-section_9-6')}</p><br/>
    </>
  );
}

const Divider = () => {
  return (
    <>
      <SDivider20/>
      <SHrAuto/>
      <SDivider20/>
    </>
  );
}