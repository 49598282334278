import React from 'react';
import { useTranslation } from "react-i18next";

import Head from '../layouts/head';
import Header from '../components/header';
import Footer from '../components/footer';
import { SHero, SHeroH1, SHeroH2, SHeroBanner, SParallax } from '../styles/heroStyle';
import { SDivider10, SDivider20, SDivider30, SDivider50, SDivider60, SDivider100 } from '../styles/dividerStyles';
import { SImgSideMd, SSide, SSideContainer } from '../styles/sidebySideStyle';
import { SHrFull, SHrAuto } from '../styles/hrStyles';
import { SLinkPrimary } from '../styles/buttonStyles';

import logo from '../assets/img/logo-light.png';

const imgHero = "hero-home.webp";
const img2 = "olive/olive.webp";
const imgSide1 = "home-side-1.webp";
const imgSide2 = "home-side-2.webp";

export default function Home() {
  const { t } = useTranslation();

  return (
    <>
      <Head
        title={t('home-title', { brand: `${process.env.REACT_APP_APP_NAME}` })}
        description={t('home-description')}
        keyword={t('home-keyword')}
        path='home'
      />
      <Hero/>
      <SDivider30/>
      <Main/>
      <SDivider100/>
      <Footer/>
    </>
  );
}

const Hero = () => {
  const { t } = useTranslation();

  return (
    <SHero style={{backgroundImage: `url('/assets/img/${imgHero}')`}}>
      <Header/>
      <SDivider60/>

      <SHeroBanner>
        <SDivider50/>
        <SHeroH1 className='text-center'>TROPIFRUIT</SHeroH1>
        <SDivider30/>
        <SHeroH2 className='text-center'>
          {t('home-hero-h2-1')}<br/>
          {t('home-hero-h2-2')}
        </SHeroH2>
        <SDivider50/>
        {/* <div className="text-center">
          <SLinkPrimary to={t('url-market')}>{t('home-hero-btn')}</SLinkPrimary>
        </div> */}
        <SDivider50/>
      </SHeroBanner>

    </SHero>
  );
}

const Main = () => {
  return (
    <main>
      <Logo/>
      <Intro/>
      <Content/>
    </main>
  );
}

const Logo = () => {
  return (
    <div className='text-center'>
      <img src={logo} height="180" alt="logo"/>
    </div>
  )
}

const Intro = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className='container text-center'>
        <DividerShort/>
        <p>
          {t('home-intro-1_1')}
          <br/><br/>
          {t('home-intro-1_2')}
          <br/><br/>
          {t('home-intro-1_3')}
          <br/><br/><br/>

          <span className="text-center">
            <SLinkPrimary to={t('url-olive_oil')}>{t('home-intro-1_4')}</SLinkPrimary>
          </span>
        </p>
      </div>

      <SDivider50/>
      <SParallax style={{backgroundImage: `url('/assets/img/${img2}')`}}/>
    </>
  );
}

const Content = () => {
  return (
    <div className='container'>
      <Divider/>
      <Goal/>
      <SDivider50/>
      <AboutOil/>
    </div>
  )
}

const Goal = () => {
  const { t } = useTranslation();

  return (
    <SSideContainer className="clearfix">
      <SImgSideMd style={{backgroundImage: `url('/assets/img/${imgSide1}')`}}/>

      <SSide>
        <div style={{padding: "20px"}}>
          <h3 className='text-center'>{t('home-goal-1_1')}</h3>
          <DividerShort/>

          <p>
            {t('home-goal-1_2')}
            <br/><br/>
            {t('home-goal-1_3')}
            <br/><br/>
            {t('home-goal-1_4')}
          </p>

          <SDivider20/>
          <div className="text-center">
            <SLinkPrimary to={t('url-contact')}>{t('home-goal-1_5')}</SLinkPrimary>
          </div>
        </div>
      </SSide>
    </SSideContainer>
  );
}

const AboutOil = () => {
  const { t } = useTranslation();

  return (
    <SSideContainer className="clearfix">
      <SSide>
        <div style={{padding: "20px"}}>
          <h3 className='text-center'>{t('home-about_oil-1_1')}</h3>
          <DividerShort/>

          <p>
            {t('home-about_oil-1_2')}
            <br/><br/>
            {t('home-about_oil-1_3')}
          </p>

          <SDivider20/>
          <div className="text-center">
            <SLinkPrimary to={t('url-olive_oil')}>{t('home-about_oil-1_4')}</SLinkPrimary>
          </div>
        </div>
      </SSide>

      <SImgSideMd style={{backgroundImage: `url('/assets/img/${imgSide2}')`}}/>
    </SSideContainer>
  )
}

const Divider = () => {
  return (
    <>
      <SDivider50/>
      <SHrAuto style={{background: "rgb(230, 189, 25)"}}/>
      <SDivider10/>
      <SHrFull style={{background: "rgb(107, 142, 35)"}}/>
      <SDivider10/>
      <SHrAuto style={{background: "rgb(230, 189, 25)"}}/>
      <SDivider50/>
    </>
  )
}

const DividerShort = () => {
  return (
    <>
      <SDivider20/>
      <SHrAuto style={{background: "rgb(230, 189, 25)"}}/>
      <SDivider20/>
    </>
  )
}