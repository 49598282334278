import React, { useEffect } from 'react';

import {
  SContainer,
  SDown,
  SUp,
  SValue
} from "./styles";

import { SlArrowUp } from "react-icons/sl";
import { SlArrowDown } from "react-icons/sl";

const Counter = ({min = 0, max, step = 1, value, onChange}) => {

  useEffect(() => {
  }, [value]);

  const handleCounterDown = () => {
    value = ((value - step) >= min) ? (value - step) : min;
    onChange(value);
  }

  const handleCounterUp = () => {
    value = ((value + step) <= max) ? (value + step) : max;
    onChange(value);
  }

  return (
    <SContainer className='clearfix'>
      <SDown onClick={() => handleCounterDown()} type='button'><SlArrowDown/></SDown>
        <SValue>{value}</SValue>
      <SUp onClick={() => handleCounterUp()} type='button'><SlArrowUp/></SUp>
    </SContainer>
  );
}

export default Counter;