import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { CartContext } from "../../services/context/Cart";
import apiGetProducts from '../../services/api/products';
import Head from '../../layouts/head';
import Header from '../../components/header';
import Footer from '../../components/footer';
import Modal from '../../components/modal';
import { BannerError } from '../../components/banner';
import { PaginationNumber } from '../../components/pagination';
import { SMiniHero } from '../../styles/heroStyle';
import { SDivider20, SDivider50, SDivider100, SDivider10 } from '../../styles/dividerStyles';
import { SBtnPrimary } from '../../styles/buttonStyles';
import { SHrLeft, SHrFull, SHrAuto } from '../../styles/hrStyles';
import {
  SStall,
  SProductCard,
  SProductPrice,
  SProductImg,
  SProductTitle,
  SProductAddCart
} from './styles';

const imgHero = "olive/hero-olive_oil.jpg";

export default function Market() {
  const { t } = useTranslation();

  return (
    <>
      <Head
        title={t('market-title', { brand: `${process.env.REACT_APP_APP_NAME}` })}
        description={t('market-description')}
        keyword={t('market-keyword')}
        path='market'
      />
      <Hero/>
      <SDivider50/>
      <Main/>
      <SDivider100/>
      <Footer/>
    </>
  );
}

const Hero = () => {
  return (
    <SMiniHero style={{backgroundImage: `url('/assets/img/${imgHero}')`}}>
      <Header/>
    </SMiniHero>
  )
}

const Main = () => {
  return (
    <main className='container'>
      <Intro/>
      <Divider/>
      <Stall/>
    </main>
  );
}

const Intro = () => {
  const { t } = useTranslation();

  return (
    <div className='text-center'>
      <h1>{t('market-h1')}</h1>
      <SDivider20/>
      <p>
        {t('market-intro-1')}
        <br/>
        {t('market-intro-2')} <Link to={t('url-contact')}>{t('market-intro-3')}</Link> {t('market-intro-4')}
      </p>
    </div>
  )
}

const Stall = () => {
  const [errMsg, setErrMsg] = useState();
  const [products, setProducts] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const [totalPages, setTotalPages] = useState(1);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const getProducts = async () => {
      try {
        setErrMsg("");

        const response = await apiGetProducts(offset);
        setTotalPages(calculateTotalPages(response.data.totalItems));
        setProducts(response.data.products);
      } catch (err) {
        if (!err?.response) {
          setErrMsg("No Server Response");
        }
      }
    }

    getProducts();

    function calculateTotalPages(totalItems) {
      const pages = Math.floor(totalItems / itemsPerPage);
      return (totalItems % itemsPerPage) > 0 ? pages + 1 : pages;
    }

    // eslint-disable-next-line
  }, [offset]);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
    setOffset((selectedPage * itemsPerPage) - itemsPerPage);
  }

  return (
    <>
      <BannerError>{errMsg}</BannerError>

      <SStall>
        { products?.length ? products.map((product, i) =>
            <ProductCard product={product} key={i}/>
          ) : <></>
        }
      </SStall>

      <PaginationNumber
        totalPages={totalPages}
        currentPage={currentPage}
        onChange={handlePageChange}
      />
    </>
  )
}

const ProductCard = ({product}) => {
  const navigate = useNavigate();
  const { addToCart } = useContext(CartContext);
  const { t } = useTranslation();
  const [visibled, setVisibled] = useState(false);

  const handlerAddCart = () => {
    setVisibled(true);
    addToCart(product);
  }

  return (
    <>
      <SProductCard>
        <SProductPrice>{product.price} $</SProductPrice>
        <SProductImg style={{backgroundImage: `url('${process.env.REACT_APP_API_DOMAIN}/image/${product.img}')`}}/>

        <SDivider20/>
        <SProductTitle>{product.name}</SProductTitle>

        <SDivider10/>
        <SHrLeft/>
        <SDivider10/>

        <p>{product.description}</p>
        <SProductAddCart>
          <SBtnPrimary onClick={() => handlerAddCart()}>{t('market-add_card')}</SBtnPrimary>
        </SProductAddCart>
      </SProductCard>

      <Modal
        visibled={visibled}
        closeModal={() => setVisibled(!visibled)}
        title={t('market-modal-title')}
        confirmBtnText={t('market-modal-confirm')}
        onConfirm={() => navigate(t('url-cart'))}
        cancelBtnText={t('market-modal-cancel')}
        onCancel={() => setVisibled(!visibled)}
      />
    </>
  )
}

const Divider = () => {
  return (
    <>
      <SDivider50/>
      <SHrAuto style={{background: "rgb(230, 189, 25)"}}/>
      <SDivider10/>
      <SHrFull style={{background: "rgb(107, 142, 35)"}}/>
      <SDivider10/>
      <SHrAuto style={{background: "rgb(230, 189, 25)"}}/>
      <SDivider50/>
    </>
  )
}