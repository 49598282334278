import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { UserContext } from "../../../services/context/User";
import { SErrorBanner } from "../../../components/banner/styles";
import { SContainerForm, SGridContainerTwo } from './styles';
import { SDivider20 } from '../../../styles/dividerStyles';

export default function FormPassword() {
  const { t } = useTranslation();
  const {
    setUserPassword,
    getUserPassword,
    setUserOldPassword,
    isValidUserPassword,
    setUserMatchPassword,
    isPasswordsMatch
  } = useContext(UserContext);

  return (
    <SContainerForm>
      <h4>{t('form_new_password-h1')}</h4>
      <SDivider20/>

      <label htmlFor='oldPassword'>{t('form_new_password-old_password')}</label>
      <input
        type="password"
        id="oldPassword"
        onChange={(e) => setUserOldPassword(e.target.value)}
        placeholder={t('form_new_password-old_password')}
        maxLength="32"
        autoComplete="new-password"
        required
      />

      <SDivider20/>

      <SGridContainerTwo>
        <div>
          <label htmlFor='password'>{t('form_new_password-new_password')}</label>
          <input
            type="password"
            id="password"
            onChange={(e) => setUserPassword(e.target.value)}
            placeholder={t('form_new_password-new_password')}
            maxLength="32"
            autoComplete="new-password"
            required
            aria-invalid={isValidUserPassword() ? "false" : "true"}
            aria-describedby="passwordnote"
          />
          { getUserPassword() && !isValidUserPassword() &&
            <SErrorBanner id="passwordnote">
              <p>{t('form_new_password-error-new_password')}</p>
            </SErrorBanner>
          }
        </div>

        <div>
          <label htmlFor='repassword'>{t('form_new_password-new_repassword')}</label>
          <input
            type="password"
            id="repassword"
            onChange={(e) => setUserMatchPassword(e.target.value)}
            placeholder={t('form_new_password-new_repassword')}
            maxLength="32"
            autoComplete="new-password"
            required
            aria-invalid={isPasswordsMatch() ? "false" : "true"}
            aria-describedby="matchpasswordnote"
          />
          { !isPasswordsMatch() &&
            <SErrorBanner id="matchpasswordnote">
              <p>{t('form_new_password-error-new_repassword')}</p>
            </SErrorBanner>
          }
        </div>
      </SGridContainerTwo>
    </SContainerForm>
  );
}