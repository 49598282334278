import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

  html {
    height: 100%;

    line-height: 1.0;
    -webkit-text-size-adjust: 100%;

    @media (max-width: 479px) {
      font-size: 95%;
    }

    @media (max-width: 400px) {
      font-size: 90%;
    }
  }
  body {
    margin: 0px;
    height: 100%;

    background: ${({ theme }) => theme.bg};

    color: ${({ theme }) => theme.text};
    font-family: 'OpenSans-Regular';
    letter-spacing: .5px;
  }
  main {display: block;}

  h1, h2, h3, h4, h5, h6 {
    margin: 0px;
    color: ${({ theme }) => theme.title};
    font-family: 'Jost-Regular';
  }
  h1{font-size: 2.5rem; line-height: 3rem;}
  h2{font-size: 2rem; line-height: 2.5rem;}
  h3{font-size: 1.6rem; line-height: 2.1rem;}
  h4{font-size: 1.5rem; line-height: 2rem;}
  h5{font-size: 1.125rem; line-height: 1.575rem}
  h6{font-size: 1rem;}

  a, a:active, a:focus {
    text-decoration: none;
    cursor: pointer;
  }
  a:hover {text-decoration: underline;}
  a:visited {color: inherit;}
  a:-webkit-any-link {color: inherit;}

  label {margin-left: 10px;}

  input, select, textarea {
    width: 100%;
    margin: 5px 0px 5px 0px;
    padding: 9px 10px;
    line-height: normal;
    color: ${({ theme }) => theme.text};
    border: 1px solid ${({ theme }) => theme.bg3};
  }
  input[type='checkbox'], [type='radio'] {
    margin: 0px;
    cursor: pointer;
  }
  input[type="text"], input[type="password"], input[type="email"], select, textarea {
    background-color: #fff;
  }
  input[type="text"][disabled], select[type="text"][disabled], textarea[type="text"][disabled] {
    color: rgb(0, 0, 0, 0.5);
    background-color: rgb(0, 0, 0, 0.1);
  }

  p {
    margin: 0px;
    line-height: 1.5rem;

    @media (max-width: 768px) {
      font-size: 90%;
    }
  }

  small {font-size: 80%;}
  strong {font-family: 'OpenSans-Bold';}

  ul {margin-block-start: 0px; margin-block-end: 0px}
  li {padding: 8px 5px}

  hr {border-width: 0px; height:2px;}
  img {border-style: none;}
  table {border-spacing: 0px; width: 100%;}
  tr:nth-child(even) {background: ${({ theme }) => theme.tableBgTrOdd}}
  tr:nth-child(odd) {background: ${({ theme }) => theme.tableBgTrEven}}
  th {padding: 6px; color: ${({ theme }) => theme.tableThColor}}
  td {padding: 6px;}
`;