import styled from "styled-components";

export const SContainerForm = styled.div`
  border-radius: 8px;
  padding: 20px;
  width: 100%;

  background-color: #fff;
  transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 36px 28px -20px rgba(0, 0, 0, 0.20);
`;

export const SGridContainer = styled.div`
  width: 100%;

  display: grid;
  gap: 10px 10px;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`;

export const SGridContainerTwo = styled.div`
  width: 100%;

  display: grid;
  gap: 10px 10px;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`;
